import React from 'react'
import { graphql } from 'gatsby'

import StaticPage from '../components/StaticPage'

function StaticPageTemplate({ data }) {
  return <StaticPage data={data}></StaticPage>
}

export const pageQuery = graphql`
  query staticPageQuery($slug: String!) {
    logo: imageSharp(original: { src: { regex: "/logo/" } }) {
      fixed(width: 120, height: 120, quality: 50, webpQuality: 50) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        excerpt
        title
      }
    }
  }
`

export default StaticPageTemplate
